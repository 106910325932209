import { Component } from "react"
import PropTypes from "prop-types"

import classnames from "classnames"
import Fittty from "shared/components/fittty"
import truncate from "lodash/truncate"
import colors from "foundation/_colors_export.scss?variables"

const noop = () => {}

export default class GrantAccessButton extends Component {
  state = {
    showSuccessConnection: true,
    showSuccessAnimation: false,
    connected: false,
    showKnob: this.props.showKnob,
    copy: this.props.ctaCopy,
    knobBgColor: {
      backgroundColor: `#${this.props.mainService.brand_color}`,
    },
    previousBgColor: {
      backgroundColor: `#${this.props.mainService.brand_color}`,
    },
  }

  render() {
    const successAniClass = this.state.showSuccessAnimation ? "success-animation" : ""
    const buttonBgColor = this.state.knobBgColor

    return (
      <div className="connect-button-outline">
        <div style={buttonBgColor} className={`connect-button ${successAniClass}`} onClick={this.handleClick}>
          <div className="meter-container">
            <div
              className={classnames({
                meter: this.state.showSuccessConnection,
              })}
            />
          </div>
          <div
            className={classnames({
              "connect-cta": true,
              connected: this.state.connected,
              "with-animation": this.state.showSuccessConnection,
            })}
            style={!this.state.showKnob && !this.state.showSuccessConnection ? buttonBgColor : null}
          >
            <div className={classnames({ "no-knob": !this.state.showKnob })}>
              <Fittty
                maxSize={this.props.connectButtonMaxFontSize}
                minSize={14}
                text={truncate(this.state.copy, { length: 24 })}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleClick = event => {
    event.preventDefault()
    this.props.onConnectClick()
  }

  // this will transit the interface from one service to another
  // if this is the final animation, prev and next will be the same props obj
  // extract to a HOC?
  startSuccessAnimation = (prev, next, callback = noop) => {
    this.setState(
      {
        showSuccessConnection: true,
        showKnob: false,
        copy: "Connecting…",
        knobBgColor: {
          backgroundColor: `#${next.lastServiceConnected.brand_color}`,
        },
        previousBgColor: {
          backgroundColor: `#${
            prev.lastServiceConnected ? prev.lastServiceConnected.brand_color : next.lastServiceConnected.brand_color
          }`,
        },
      },
      () => {
        setTimeout(
          () =>
            this.setState(
              {
                copy: "",
                knobBgColor: next.defaultBgColor,
                showSuccessAnimation: true,
              },
              () => {
                setTimeout(() => {
                  this.setState(
                    {
                      showSuccessConnection: false,
                      showSuccessAnimation: false,
                      showKnob: next.showKnob,
                      copy: next.ctaCopy,
                      knobBgColor: {
                        backgroundColor: `#${next.mainService.brand_color}`,
                      },
                    },
                    callback
                  )
                }, next.successConnectionDelay)
              }
            ),
          next.successConnectionDelay
        )
      }
    )
  }

  componentDidUpdate(prevProps) {
    const { authenticate, lastServiceConnected, animation, mainService, appletIsSaving } = this.props

    if (
      authenticate &&
      lastServiceConnected !== prevProps.lastServiceConnected &&
      (!animation.showSuccessConnection[mainService.module_name] || appletIsSaving)
    ) {
      this.startSuccessAnimation(prevProps, this.props, () => {
        this.props.updateAnimationCol({
          animation: "showSuccessConnection",
          status: { ...{ [mainService.module_name]: true, played: true } },
        })
      })
    }
  }

  componentDidMount() {
    const { autoClick, idleTimeTillAutoClick } = this.props

    // Advance to service auth URL if user doesn't click in 2.4s
    !this.props.requirementsMet() &&
      autoClick &&
      setTimeout(() => {
        this.props.onConnectClick()
      }, idleTimeTillAutoClick)
  }
}

GrantAccessButton.propTypes = {
  mainService: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
  onConnectClick: PropTypes.func.isRequired,
  showKnob: PropTypes.bool,
  ctaCopy: PropTypes.string,
  connected: PropTypes.bool,
  authenticate: PropTypes.bool,
  lastServiceConnected: PropTypes.object,
  successConnectionDelay: PropTypes.number,
  idleTimeTillAutoClick: PropTypes.number,
  defaultBgColor: PropTypes.object,
  autoClick: PropTypes.bool,
  animation: PropTypes.object.isRequired,
  updateAnimationCol: PropTypes.func.isRequired,
  requirementsMet: PropTypes.func,
  appletIsSaving: PropTypes.bool,
  connectButtonMaxFontSize: PropTypes.number,
}

GrantAccessButton.defaultProps = {
  defaultBgColor: { backgroundColor: colors.primaryTextColor },
  successConnectionDelay: 2000,
  autoClick: true,
  idleTimeTillAutoClick: 3000,
  showKnob: false,
  connected: false,
  authenticate: true,
  connectButtonMaxFontSize: 30,
}
