import { gql, graphQueryBang } from "shared/lib/graphql"

const appletDetails = gql`
  applet_trigger {
    fields {
      name
      hidden
    }
    step: trigger {
      id
      name
      module_name
      full_module_name
      normalized_module_name
      service: channel {
        id
        name
        normalized_module_name
        module_name
        image: lrg_monochrome_image_url
      }
      fields: trigger_fields {
        label
        name
        field_ui_type
        normalized_field_type
        required
        shareable
        hideable
        can_have_default
        helper_text
      }
      ingredients {
        label
        name
        example
        value_type
      }
    }
  }
  applet_queries {
    fields {
      name
      hidden
    }
    step: query {
      id
      name
      module_name
      full_module_name
      normalized_module_name
      service: channel {
        id
        name
        normalized_module_name
        module_name
        image: lrg_monochrome_image_url
      }
      fields: query_fields {
        label
        name
        normalized_module_name
        field_ui_type
        normalized_field_type
        required
        shareable
        hideable
        can_have_default
        helper_text
      }
      ingredients {
        label
        name
        example
        value_type
      }
    }
  }
  applet_actions {
    fields {
      name
      hidden
    }
    step: action {
      id
      name
      module_name
      full_module_name
      normalized_module_name
      service: channel {
        id
        name
        normalized_module_name
        module_name
        image: lrg_monochrome_image_url
      }
      fields: action_fields {
        label
        name
        normalized_module_name
        field_ui_type
        normalized_field_type
        required
        shareable
        hideable
        can_have_default
        helper_text
      }
    }
  }
`

// Only owner should access filter code
const filterCodeOwnerGQL = gql`
  query filterCodeOwnerGQL($applet_id: String!) {
    applet(id: $applet_id, in_embed_view: true) {
      filterCode: filter_code
      ${appletDetails}
    }
  }
`

const filterCodeGQL = gql`
  query filterCodeGQL($applet_id: String!) {
    applet(id: $applet_id, in_embed_view: true) {
      ${appletDetails}
    }
  }
`

export const filterCodeQuery = (appletId, isOwner) => {
  const gqlQuery = isOwner ? filterCodeOwnerGQL : filterCodeGQL

  return graphQueryBang(gqlQuery, { applet_id: appletId })
}

const aiFieldSuggestionsGQL = gql`
  query aiFieldSuggestionsGQL($applet_id: String!, $permission_id: String!, $field_name: String!) {
    ai_field_suggestion(applet_id: $applet_id, permission_id: $permission_id, field_name: $field_name) {
      value
      errors {
        parameter
        attribute
      }
    }
  }
`

export const aiFieldSuggestion = ({ appletId, permissionId, fieldName }) =>
  graphQueryBang(aiFieldSuggestionsGQL, { applet_id: appletId, permission_id: permissionId, field_name: fieldName })
