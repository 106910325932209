import { Component } from "react"
import classnames from "classnames"

import checkmarkAnimationData from "./animations/success_checkmark.json"

// Strip frames from the beginning and end of the animation
const ENTER_FRAME = 60
const EXIT_FRAME = 125

export default class SuccessLottieAnimation extends Component {
  state = {
    animationWillEnd: false,
  }

  componentDidMount() {
    // Lottie keeps adding the DOM structure for this animation every time
    // the component is mounted so we need to destroy everything
    window.lottie.destroy()

    this.lottieEl = document.querySelector("#success-animation-container")

    this.initializeAnimation()
  }

  initializeAnimation = () => {
    this.anim = window.lottie.loadAnimation({
      container: this.lottieEl,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: checkmarkAnimationData,
    })

    // When the animation hits the EXIT_FRAME value, we add a CSS animation to
    // push it to the right
    this.anim.addEventListener("enterFrame", this.onEnterFrame)
    this.anim.addEventListener("DOMLoaded", this.play)
  }

  onEnterFrame = e => {
    if (e.currentTime > EXIT_FRAME) {
      this.setState({ animationWillEnd: true })
    }
  }

  play = () => {
    // skip some frames so the animation looks snappier
    this.anim.goToAndPlay(ENTER_FRAME, true)
  }

  componentWillUnmount() {
    this.anim.removeEventListener("enterFrame", this.onEnterFrame)
    this.anim.removeEventListener("DOMLoaded", this.play)
  }

  render() {
    return (
      <div
        id="success-animation-container"
        className={classnames("success-animation-container", {
          "slide-out-from-middle": this.state.animationWillEnd,
        })}
      ></div>
    )
  }
}
