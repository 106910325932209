import ReactRailsUJS from "react_ujs"
import Rails from "@rails/ujs"

if (process.env.NODE_ENV === "development") {
  require("preact/debug")
}

import { yieldToMain } from "shared/lib/utils"
import { oneTimeSetup, sharedSetup, logCustomDatadogAction, logDatadogError } from "app/scripts/shared_setup"
import { attachNewBadgeForButtons } from "shared/scripts/new_badge_button.tsx"
import VanillaFittty from "shared/scripts/vanilla_fittty.tsx"
import RubberBandPinchColor from "app/scripts/rubber_band_pinch_color"
import BackButton from "app/scripts/back_button"

// Add assets to the manifest here only in development to support non-compiled assets
if (process.env.NODE_ENV === "development") {
  require.context("images", true)
  require.context("fonts", true)
}

oneTimeSetup()

const ogMountComponents = ReactRailsUJS.mountComponents
ReactRailsUJS.mountComponents = function (element) {}

document.addEventListener("IftttAppReady", async () => {
  sharedSetup()
  VanillaFittty()
  ReactRailsUJS.mountComponents = ogMountComponents
  ReactRailsUJS.mountComponents()

  await yieldToMain()
  attachNewBadgeForButtons()
})

Rails.start()

window.App = window.App || {}
window.App = Object.assign(window.App, {
  storedFieldPath: (resolveUrl, liveChannelId = "") => {
    if (liveChannelId === "") {
      return `/stored_fields/options?resolve_url=${resolveUrl}`
    } else {
      return `/stored_fields/options?resolve_url=${resolveUrl}&live_channel_id=${liveChannelId}`
    }
  }
})
window.App.Comps = {}
window.App.Scripts = {
  BackButton,
  RubberBandPinchColor,
  VanillaFittty,
}
window.App.Utils = {
  logCustomDatadogAction,
  logDatadogError
}

await yieldToMain()
