import { Component } from "react"
import PropTypes from "prop-types"
import { combineReducers, createStore, applyMiddleware, compose } from "redux"
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import reducers from "./reducers"
import {
  addConnectionDependency,
  addMainService,
  setApplet,
  setConfig,
  setUser,
  setError,
  connectClicked,
  updateNextToBeConnected,
  resetAuthRequested,
} from "./actions"

import initSubscriber from "redux-subscriber"
import { get, post, put, del, patch } from "shared/lib/api"
import Connection from "./connection"
import { loadState, saveState } from "shared/localstorage_helper"

export default class ConnectionCard extends Component {
  constructor(props) {
    super(props)

    const combinedReducers = combineReducers(reducers)
    const localStorageId = `connectionId-${props.connection.id}-${props.sessionId}`
    const persistedState = loadState(localStorageId)

    // make api methods available to all reducers
    const api = { get, post, put, del, patch }

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    this.store = createStore(
      combinedReducers,
      persistedState,
      composeEnhancers(applyMiddleware(thunk.withExtraArgument(api)))
    )

    initSubscriber(this.store)

    this.store.subscribe(() => {
      saveState(this.store.getState(), localStorageId)
    })

    // Set Initial State
    this.store.dispatch(
      setApplet({
        ...props.connection,
        appletDetails: this.props.appletDetails || {},
      })
    )

    this.store.dispatch(
      setConfig({
        activationPath: props.urls.activationPath,
        returnTo: props.urls.returnTo,
        joinPath: props.urls.joinPath,
        savePath: props.urls.savePath,
        disablePath: props.urls.disablePath,
        editPath: props.urls.editPath,
        createPath: props.urls.createPath,
        myAppletURL: props.urls.myAppletURL,
        appStorePath: props.urls.appStorePath,
        googlePlayStorePath: props.urls.googlePlayStorePath,
        userDevice: props.userDevice,
        needsConfig: props.needsConfig,
        dynamicConfig: props.dynamicConfig,
        localStorageId,
        appletEligibleForMobilePrompt: props.appletEligibleForMobilePrompt,
        showPublishCta: props.showPublishCta,
      })
    )

    let connectClick = false
    if (props.signedIn && persistedState?.user?.authRequestedAt) {
      const timeSinceLogin = new Date().getTime() - new Date(persistedState.user.authRequestedAt).getTime()
      connectClick = timeSinceLogin < 5 * 60 * 1000
      // clear the timestamp to avoid interfering with this state during connection process
      this.store.dispatch(resetAuthRequested())
    }
    this.store.dispatch(connectClicked(connectClick))

    this.store.dispatch(
      setUser({
        ...props.user,
        signedIn: props.signedIn,
        code: props.code,
      })
    )

    // Ensure that user won't be pushed forward when reload page
    this.store.dispatch(setError(props.error))

    const servicesToBeConnected = []

    props.services.forEach(connection => {
      let conn = { ...connection, granted: connection.connected }

      const permissionState = this.store.getState().connections.permissions[connection.module_name]

      // IF previous state was disconnect and the new load data is connect, fire the event.
      if (permissionState && !permissionState.granted && connection.connected) {
        // Ensure that user will be pushed forward when back to connection page
        // after authorize oauth service
        this.store.dispatch(connectClicked(true))
      }

      this.store.dispatch(addConnectionDependency(conn))

      if (!connection.connected) {
        servicesToBeConnected.push(connection)
      }
    })

    this.store.dispatch(addMainService(props.mainService))

    // set the next service to be connected. Skip service already connected.
    if (servicesToBeConnected[0]) {
      this.store.dispatch(updateNextToBeConnected(servicesToBeConnected[0].module_name))
    }
  }

  componentDidMount() {
    window.addEventListener("pageshow", this.handlePageShow)
  }

  componentWillUnmount() {
    window.removeEventListener("pageshow", this.handlePageShow)
  }

  handlePageShow = event => {
    if (event.persisted) {
      window.location.reload()
    }
  }

  render() {
    return (
      <Provider store={this.store}>
        <Connection
          urls={this.props.urls}
          webhookUrl={this.props.webhookUrl}
          user={this.props.user}
          sessionId={this.props.sessionId}
          proPlusProductId={this.props.proPlusProductId}
          services={this.props.services}
        />
      </Provider>
    )
  }
}

ConnectionCard.propTypes = {
  connection: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string,
    description: PropTypes.string.isRequired,
    configurations: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.string,
        description: PropTypes.string,
      })
    ),
    value_propositions: PropTypes.arrayOf(
      PropTypes.shape({
        benefit: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
      })
    ),
    applet_feedback_by_user: PropTypes.bool,
  }),
  appletDetails: PropTypes.object,
  mainService: PropTypes.shape({
    name: PropTypes.string.isRequired,
    module_name: PropTypes.string.isRequired,
    brand_color: PropTypes.string.isRequired,
    lrg_variant_image_url: PropTypes.string,
  }),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      module_name: PropTypes.string.isRequired,
      brand_color: PropTypes.string.isRequired,
      lrg_variant_image_url: PropTypes.string,
    })
  ),
  proPlusProductId: PropTypes.string.isRequired,
  signedIn: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userDevice: PropTypes.string.isRequired,
  error: PropTypes.object,
  needsConfig: PropTypes.bool,
  dynamicConfig: PropTypes.bool,
  code: PropTypes.string,
  sessionId: PropTypes.string.isRequired,
  appletEligibleForMobilePrompt: PropTypes.bool,
  showPublishCta: PropTypes.bool.isRequired,
  urls: PropTypes.shape({
    createPath: PropTypes.string.isRequired,
    returnTo: PropTypes.string,
    activationPath: PropTypes.string.isRequired,
    savePath: PropTypes.string.isRequired,
    disablePath: PropTypes.string.isRequired,
    editPath: PropTypes.string.isRequired,
    myAppletURL: PropTypes.string.isRequired,
    joinPath: PropTypes.string.isRequired,
    appStorePath: PropTypes.string.isRequired,
    googlePlayStorePath: PropTypes.string.isRequired,
    modalSubscriptionUrl: PropTypes.string,
  }),
  webhookUrl: PropTypes.string,
}

ConnectionCard.defaultProps = {
  error: { type: null, message: null },
  appletEligibleForMobilePrompt: false,
}
