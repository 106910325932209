import { Component } from "react"
import PropTypes from "prop-types"

import AnimationEvent from "app/scripts/animation_event"

import colors from "foundation/_colors_export.scss?variables"
import classnames from "classnames"
import Fittty from "shared/components/fittty"
import { ctaCopyToConnect } from "../utils"

export default class ReconnectButton extends Component {
  state = {
    connected: true,
    playDisableAnimation: false,
    playMeterAnimation: false,
    playSuccessAnimation: false,
    showKnob: this.props.showKnob,
    ctaCopy: "",
    knobBgColor: {
      backgroundColor: `#${this.props.mainService.brand_color}`,
    },
  }

  render() {
    const {
      playDisableAnimation,
      playSuccessAnimation,
      playMeterAnimation,
      showKnob,
      connected,
      knobBgColor,
      ctaCopy,
    } = this.state
    const mainService = this.props.mainService
    const showKnobStyle = showKnob ? { opacity: 1 } : { opacity: 0 }

    return (
      <div className="connect-button-outline">
        <div style={this.props.defaultBgColor} className="connect-button" onClick={this.handleClick}>
          <div className="meter-container">
            <div
              id="progress-meter"
              className={classnames({
                meter: playMeterAnimation,
              })}
            />
          </div>
          <div
            id="connect-ball"
            className={classnames("service-icon", {
              "slide-in": playDisableAnimation,
              "alt-brand-color": this.props.knobBgColorForDarkBrands,
              connected,
            })}
            style={{ ...knobBgColor, ...showKnobStyle }}
          >
            {this.props.dynamicConfig && (
              <img
                alt={mainService.name}
                title={mainService.name}
                src={mainService.lrg_monochrome_image_url || mainService.lrg_variant_image_url}
                width="40"
                height="40"
              />
            )}
          </div>
          <div
            className={classnames("connect-cta", {
              connected,
              "fade-out-fade-in": playSuccessAnimation,
            })}
            id="cta-copy"
          >
            <div className={classnames({ "no-knob": !showKnob })}>
              <Fittty maxSize={this.props.connectButtonMaxFontSize} minSize={14} text={ctaCopy} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleClick = event => {
    event.preventDefault()
    this.props.onConnectClick()
  }

  componentDidMount() {
    if (!this.props.connected && this.state.connected) {
      this.unlatch()
    }
  }

  unlatch = () => {
    const { disconnectClicked } = this.props

    // don't play aninmations if the flow has not been started in this "session"
    if (!disconnectClicked) {
      this.setState({
        connected: false,
        playDisableAnimation: false,
        playMeterAnimation: false,
        ctaCopy: ctaCopyToConnect(this.props.dynamicConfig && this.props.mainService.short_name),
      })
    } else {
      this.knobElement = document.querySelector("#connect-ball")
      this.setState(
        {
          connected: false,
          playDisableAnimation: true,
          showKnob: true,
          ctaCopy: "",
        },
        () => {
          this.knobElement.addEventListener(AnimationEvent(), this.handleKnobAnimationEnd)
        }
      )
    }
  }

  handleKnobAnimationEnd = () => {
    this.meterElement = document.querySelector("#progress-meter")
    this.setState(
      {
        showKnob: false,
        ctaCopy: "Disconnecting…",
        playMeterAnimation: true,
        playDisableAnimation: false,
        playSuccessAnimation: false,
      },
      () => {
        this.meterElement.addEventListener(AnimationEvent(), this.handleMeterAnimationEnd)
        this.knobElement.removeEventListener(AnimationEvent(), this.handleKnobAnimationEnd)
      }
    )
  }

  handleMeterAnimationEnd = () => {
    this.ctaElement = document.querySelector("#cta-copy")
    this.setState(
      {
        ctaCopy: "Disconnected",
        playMeterAnimation: false,
        playDisableAnimation: false,
        playSuccessAnimation: true,
      },
      () => {
        this.ctaElement.addEventListener(AnimationEvent(), this.handleCtaAnimationEnd)
        this.meterElement.removeEventListener(AnimationEvent(), this.handleMeterAnimationEnd)
      }
    )
  }

  handleCtaAnimationEnd = () => {
    this.setState(
      {
        ctaCopy: ctaCopyToConnect(this.props.dynamicConfig && this.props.mainService.short_name),
        showKnob: true,
        playSuccessAnimation: false,
      },
      () => {
        this.ctaElement.removeEventListener(AnimationEvent(), this.handleCtaAnimationEnd)
      }
    )
  }
}

ReconnectButton.propTypes = {
  mainService: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
  onConnectClick: PropTypes.func.isRequired,
  connectClicked: PropTypes.bool.isRequired,
  disconnectClicked: PropTypes.bool.isRequired,
  showKnob: PropTypes.bool,
  ctaCopy: PropTypes.string,
  connected: PropTypes.bool,
  dynamicConfig: PropTypes.bool,
  defaultBgColor: PropTypes.object,
  animation: PropTypes.object.isRequired,
  updateAnimationCol: PropTypes.func.isRequired,
  connectButtonMaxFontSize: PropTypes.number,
  knobBgColorForDarkBrands: PropTypes.bool,
}

ReconnectButton.defaultProps = {
  ctaCopy: "Reconnect",
  defaultBgColor: { backgroundColor: colors.primaryTextColor },
  showKnob: false,
  connected: false,
  connectButtonMaxFontSize: 30,
}
