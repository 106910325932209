import { updateTextSize } from "auto-text-size"

const VanillaFittty = scope => {
  (scope || document).querySelectorAll("[data-fittty]").forEach(el => {
    const containerEl = el
    const innerEl = containerEl.children[0]

    updateTextSize({
      innerEl,
      containerEl,
      mode: el.dataset.fitttyMode || "box",
      minFontSizePx: el.dataset.fitttyMin || 20,
      maxFontSizePx: el.dataset.fitttyMax || 52,
      fontSizePrecisionPx: 0.1,
    })
  })
}

export default VanillaFittty
