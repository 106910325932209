import classnames from "classnames"
import parse from "html-react-parser"
import Fittty from "shared/components/fittty"
import { WorksWithContent } from "./works_with_content"
import { compactInteger } from "humanize-plus"
import userIconPng from "images/web/applet_card/user-icon.png"
import appletOrConnection from "shared/lib/use_applet_or_connection"
import truncate from "lodash/truncate"
import unicorn from "images/web/applet_card/404_unicorn_blue.png"
import iftttLogo from "images/web/applet_card/ifttt-logo-white.png"
import proPlusBadge from "images/plan-badges/pro_plus.svg"
import proBadge from "images/plan-badges/pro.svg"

import "./small_applet_card.scss"
import classNames from "classnames"

interface ConnectionShape {
  author_id: string
  background_images: {
    background_image_url_1x: string
    sm_background_image_url: string
  }
  brand_color: string
  channels: Array<{ module_name: string; brand_color: string }>
  description: string
  id: string
  installs_count: number
  intermediate_pro_features: boolean
  name: string
  pro_features: boolean
  service_slug: string
}

// styleName and className need to be passed manually
// otherwise the transpiler does not detect the properties
const ShareableAppletWrapper = ({ href, className, ...attributes }) => {
  return <a className={className} styleName="applet-card-body" href={href} {...attributes}></a>
}

const NotFoundContent = () => {
  return (
    <div className="not-found">
      <img className="error-image" src={unicorn} alt="404 unicorn" width="108" height="107" />
      <div className="text-content">
        This link is no longer valid. <br /> Explore more automation ideas on IFTTT.com
      </div>
      <div className="footer">
        <div className="dotted-line" />
        <div className="ifttt-logo">
          <img src={iftttLogo} alt="IFTTT logo" width="80" height="21.2" />
        </div>
      </div>
    </div>
  )
}

const Name = ({ name, backgroundImagePresent }: { name: string; backgroundImagePresent: boolean }) => {
  return (
    <span
      className={classnames("name", {
        "with-image": backgroundImagePresent,
      })}
      styleName={classnames({
        "with-image": backgroundImagePresent,
      })}
    >
      <Fittty mode="box" minSize={30} maxSize={36} text={name} />
    </span>
  )
}

const CardFooter = ({
  applet,
  appletType,
  humanizedInstallNumber,
  installsCount,
  needsProBadge,
}: {
  applet: ConnectionShape
  appletType: string
  humanizedInstallNumber: string
  installsCount: number
  needsProBadge: boolean
}) => {
  return (
    <>
      {installsCount > 0 && (
        <div
          className="installs"
          aria-label={`${humanizedInstallNumber} users`}
          title={`${humanizedInstallNumber} users enabled this ${appletType}`}
        >
          <span className="verified">
            <img src={userIconPng} alt="user icon" height="23" width="18" />
          </span>
          <span className="number">{humanizedInstallNumber}</span>
        </div>
      )}
      {needsProBadge && (
        <span title={`Applet has pro features`} className="card-badge">
          {parse(applet.pro_features ? proPlusBadge : proBadge)}
        </span>
      )}
    </>
  )
}

export default function ShareableAppletCard({
  applet,
  appletUrl,
  width,
}: {
  applet: ConnectionShape | null
  appletUrl: string
  width: number
}) {
  const backgroundImage =
    applet?.background_images?.background_image_url_1x || applet?.background_images?.sm_background_image_url

  if (!applet) {
    return (
      <ShareableAppletWrapper
        style={{ backgroundColor: "#222222" }}
        className={classnames("applet-card-body", { "applet-card-no-link": !appletUrl })}
        href={appletUrl}
      >
        <NotFoundContent />
      </ShareableAppletWrapper>
    )
  }

  const mainService =
    applet.channels.find(el => {
      return el.module_name === applet.service_slug
    }) || applet.channels[0]

  const style: { backgroundImage?: string; backgroundColor: string; backgroundSize?: string } = {
    backgroundColor: `#${mainService.brand_color}`,
  }
  const humanizedInstallNumber = compactInteger(applet.installs_count, 1)
  const appletType = appletOrConnection(applet)
  const appletName = truncate(applet.name, { length: 100 })
  const needsProBadge = applet.pro_features || applet.intermediate_pro_features
  const setSmallIcons = width < 480

  if (backgroundImage) {
    style.backgroundImage = `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.4)), url('${backgroundImage}')`
    style.backgroundSize = "cover"
  }

  return (
    <>
      <ShareableAppletWrapper
        style={style}
        className={classnames("applet-card-body", `applet-${applet.id}`, { "applet-card-no-link": !appletUrl })}
        href={appletUrl}
      >
        <div className="header">
          <div className={classnames("works-with-container", { "small-icon": setSmallIcons })}>
            <WorksWithContent applet={applet} mainService={mainService} lazy={false} />
          </div>
        </div>
        <div className="main-content">
          <Name name={appletName} backgroundImagePresent={!!backgroundImage} />
        </div>
        <div className={classNames("card-footer", { badged: needsProBadge })}>
          <CardFooter
            applet={applet}
            appletType={appletType}
            humanizedInstallNumber={humanizedInstallNumber}
            installsCount={applet.installs_count}
            needsProBadge={needsProBadge}
          />
        </div>
      </ShareableAppletWrapper>
      {backgroundImage && <img src={backgroundImage} style={{ position: "absolute", top: "-9999px" }} />}
    </>
  )
}
