import PropTypes from "prop-types"
import { AutoTextSize } from "auto-text-size"

const Fittty = ({ minSize, maxSize, mode, text }) => (
  <AutoTextSize mode={mode} minFontSizePx={minSize} maxFontSizePx={maxSize} as="span">
    {text}
  </AutoTextSize>
)

Fittty.propTypes = {
  text: PropTypes.string.isRequired,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  mode: PropTypes.string,
}

Fittty.defaultProps = {
  minSize: 20,
  maxSize: 52,
  mode: "oneline",
}

export default Fittty
