import { Component } from "react"
import PropTypes from "prop-types"

import Modal from "shared/components/modal"
import Cookies from "js-cookie"

import SMSForm from "./sms_form"
import AppDownloadButton from "./app_download_button"

import "./index.scss"

export default class MobileAlert extends Component {
  constructor(props) {
    super(props)

    this.state = { showModal: false }
  }

  UNSAFE_componentWillMount() {
    const { applet, user } = this.props

    // Session cookies, will be removed when browser closed.
    const cookieKey = `mobile_alert_${user.login}_${applet.id}`
    const hasViewedMobileAlert = Cookies.get(cookieKey)

    if (!hasViewedMobileAlert) {
      this.setState({ showModal: true })

      Cookies.set(cookieKey, true)
    }
  }

  closeMobileAlertModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <div className="mobile-alert-modal">
        <Modal
          show={this.state.showModal}
          closeListener={this.closeMobileAlertModal}
        >
          <div className="mobile-alert-container">
            <p>
              This Applet needs
              <br /> the IFTTT app
            </p>
            {this.props.userDevice === "desktop" ? (
              <SMSForm
                applet={this.props.applet}
                closeModal={this.closeMobileAlertModal}
                appStorePath={this.props.appStorePath}
                googlePlayStorePath={this.props.googlePlayStorePath}
              />
            ) : (
              <AppDownloadButton
                applet={this.props.applet}
                userDevice={this.props.userDevice}
                appStorePath={this.props.appStorePath}
                googlePlayStorePath={this.props.googlePlayStorePath}
              />
            )}
          </div>
        </Modal>
      </div>
    )
  }
}

MobileAlert.propTypes = {
  userDevice: PropTypes.string,
  user: PropTypes.shape({
    login: PropTypes.string,
  }),
  appStorePath: PropTypes.string.isRequired,
  googlePlayStorePath: PropTypes.string.isRequired,
  applet: PropTypes.shape({
    id: PropTypes.string,
    requires_mobile_app: PropTypes.bool,
    status: PropTypes.string,
    just_enabled: PropTypes.bool,
  }).isRequired,
}

MobileAlert.defaultProps = {
  userDevice: "desktop",
}
