import { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import parse from "html-react-parser"
import { capitalize } from "humanize-plus"
import { getUserLiveApplets } from "shared/lib/graphql"
import { getServiceAndApplets } from "./queries"
import Spinner from "shared/components/spinner"
import DiscoverServiceAppletCard from "./applet_card"
import { MyServiceView } from "../my_service_view"
import SuggestionModal from "shared/components/suggestion_modal"
import plusIcon from "images/icons/plus-icon-soft.svg"
import plusIconMobile from "images/icons/plus-icon-soft-mobile.svg"
import proPlusBadge from "images/plan-badges/pro_plus.svg"
import proBadge from "images/plan-badges/pro.svg"

import { singularFromPlural } from "shared/lib/utils"
import { serviceShape, connectionShape } from "app/lib/shared_prop_types"

import dsv from "./discover_service_view.scss"
import wwc from "./works_well_card.scss"

const tqaTypes = ["triggers", "queries", "actions"]

const spinnerConfig = {
  left: "50%",
  bottom: "0%",
  length: 6,
  width: 6,
  radius: 20,
  corners: 0,
  scale: 0.55,
  position: "relative",
}

export const DiscoverServiceView = props => {
  const signedIn = !!window.App.user?.id
  const [myApplets, setMyApplets] = useState([])
  const [loading, setLoading] = useState(signedIn)
  const [applets, setApplets] = useState(props.loggedOutDiscoverApplets)
  const [currentTab, setCurrentTab] = useState(props.currentTab)
  const [feedbackTqaType, setFeedbackTqaType] = useState(null)
  const isDesktop = window.matchMedia?.(`(min-width: 724px)`)?.matches

  const navigateToTab = currentView => {
    setCurrentTab(currentView)
    let title
    let url
    switch (currentView) {
      case "applets":
        title = props.descriptions.service
        url = props.urls.servicePath
        break
      case "my_applets":
        title = props.descriptions.myApplets
        url = props.urls.myAppletsPath
        break
      case "details":
        title = props.descriptions.details
        url = props.urls.detailsPath
        break
    }

    history.pushState({ currentView }, title, url)
  }

  const handlePopState = event => {
    if (event.state?.hasOwnProperty("currentView")) setCurrentTab(event.state.currentView)
  }

  const applyAppletsFetch = (discover, live_applets) => {
    const relevantApplets = (live_applets || []).map(a => a.applet.normalized_applet)

    setMyApplets(relevantApplets)

    if (currentTab === "my_applets" && relevantApplets.length === 0) {
      setCurrentTab("applets")
    }

    const removedIds = relevantApplets.map(a => a.id)
    const availableApplets = discover.applets_for_channel.filter(a => !removedIds.includes(a.id))
    setApplets(availableApplets)
    setLoading(false)

    if (signedIn && !relevantApplets.length && !availableApplets.length) {
      const headerCreateButton = document.querySelector(".horizontal-ctas > a[data-button-type='create']")
      if (headerCreateButton) {
        headerCreateButton.style.display = "hidden"
      }
    }
  }

  useEffect(() => {
    window.addEventListener("popstate", handlePopState)

    return () => window.removeEventListener("popstate", handlePopState)
  })

  useEffect(() => {
    if (signedIn) {
      let appletsPromises = Promise.all([
        getServiceAndApplets(props.service.module_name),
        getUserLiveApplets(null, null, props.service.module_name),
      ])

      appletsPromises
        .then(promiseValues => {
          const [
            {
              channel: { discover },
            },
            { live_applets },
          ] = promiseValues

          applyAppletsFetch(discover, live_applets)
        })
        .catch(err => console.warn(err))
    }
  }, [])

  const Tab = ({ name, label }) => (
    <span
      role="tab"
      aria-label={label}
      onClick={() => navigateToTab(name)}
      styleName={classnames({
        "dsv.active": currentTab === name,
      })}
    >
      {label}
    </span>
  )

  const TabContent = ({ currentTab }) => {
    switch (currentTab) {
      case "details":
        return (
          <section styleName="dsv.tab-content dsv.column">
            {tqaTypes
              .filter(tqa => props[tqa].length)
              .map(tqas => (
                <Fragment key={tqas}>
                  <div styleName="dsv.header">
                    <h3>{capitalize(tqas)}</h3>
                  </div>
                  <ul styleName="dsv.tqa-list" className="web-applet-cards tqa">
                    {props[tqas].map(({ url, name, description, tier }, i) => (
                      <li
                        className="my-web-applet-card web-applet-card web-applet-card-template tqa"
                        styleName={tier !== "free" ? "dsv.badged" : ""}
                        key={i}
                      >
                        <a
                          className="web-applet-card applet-card-body tqa"
                          styleName="wwc.connect-card"
                          style={{ backgroundColor: props.service.brand_color }}
                          href={url}
                        >
                          <div className="content tqa">
                            <span styleName="dsv.title">{name}</span>
                            <span
                              styleName={classnames("dsv.description", {
                                "dsv.badged": tier !== "free",
                              })}
                            >
                              {description}
                            </span>
                            {tier !== "free" && (
                              <span
                                title={`This ${singularFromPlural(tqas)} has pro features`}
                                className="card-badge fixed"
                                styleName="dsv.badge"
                              >
                                {parse(tier === "pro" ? proPlusBadge : proBadge)}
                              </span>
                            )}
                          </div>
                        </a>
                      </li>
                    ))}
                    {signedIn && (
                      <li className="my-web-applet-card web-applet-card web-applet-card-template tqa">
                        <a
                          className="web-applet-card applet-card-body tqa"
                          styleName="wwc.connect-card dsv.suggestion-card"
                          role="button"
                          onClick={() => setFeedbackTqaType(singularFromPlural(tqas))}
                        >
                          <div styleName="dsv.content">
                            <span styleName="dsv.svg-icon">{parse(isDesktop ? plusIcon : plusIconMobile)}</span>
                            <span styleName="dsv.title">Suggest new {singularFromPlural(tqas)}</span>
                          </div>
                        </a>
                      </li>
                    )}
                  </ul>
                </Fragment>
              ))}
          </section>
        )
      case "my_applets":
        return (
          <>
            <h2 styleName="dsv.services">Your {props.service.name} Applets</h2>
            {myApplets.length > 0 && (
              <section className="my_services">
                <MyServiceView applets={myApplets} />
              </section>
            )}
          </>
        )
      default:
        return (
          <>
            {loading && <Spinner config={spinnerConfig} />}
            {!loading && applets?.length === 0 && (
              <div className="diy-section">
                <a href={props.urls.diyCreationPath} className="cta-your-own-applet">
                  <span>
                    No Applets found.
                    <br />
                    Make your own from scratch!
                  </span>
                  <button className="button-secondary">Create</button>
                </a>
              </div>
            )}
            {applets?.length > 0 && (
              <h2 styleName="dsv.services">Popular {props.service.name} workflows &amp; automations</h2>
            )}
            <section className="discover_services">
              <ul className="web-applet-cards" data-testid="discover-applets">
                {applets?.map((applet, idx) => {
                  return <DiscoverServiceAppletCard key={idx} applet={applet} position={idx} />
                })}
              </ul>
            </section>
          </>
        )
    }
  }

  return (
    <>
      {feedbackTqaType && signedIn && (
        <SuggestionModal
          channelId={props.service.id}
          onClose={() => setFeedbackTqaType(null)}
          feedbackTqaType={feedbackTqaType}
          serviceName={props.service.name}
        />
      )}
      <div styleName="dsv.tab-content dsv.filters" role="tablist">
        <Tab name="applets" label="Applets" />
        {!!myApplets.length && <Tab name="my_applets" label="My Applets" />}
        <Tab name="details" label="Details" />
      </div>
      <TabContent currentTab={currentTab} />
    </>
  )
}

DiscoverServiceView.tqaShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  module_name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
})

DiscoverServiceView.propTypes = {
  service: serviceShape.isRequired,
  urls: PropTypes.shape({
    diyCreationPath: PropTypes.string.isRequired,
    discoverJsonPath: PropTypes.string.isRequired,
    detailsPath: PropTypes.string.isRequired,
    myAppletsPath: PropTypes.string.isRequired,
    servicePath: PropTypes.string.isRequired,
  }),
  descriptions: PropTypes.shape({
    details: PropTypes.string.isRequired,
    myApplets: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
  }),
  currentTab: PropTypes.string.isRequired,
  triggers: PropTypes.arrayOf(DiscoverServiceView.tqaShape).isRequired,
  queries: PropTypes.arrayOf(DiscoverServiceView.tqaShape).isRequired,
  actions: PropTypes.arrayOf(DiscoverServiceView.tqaShape).isRequired,
  loggedOutDiscoverApplets: PropTypes.arrayOf(connectionShape),
}

DiscoverServiceView.defaultProps = {
  applets: [],
}
